import { fetchWithToken } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';
import { DocumentLayer, EditableNode, TransactionMember } from './types.d';

export async function getPdfDocument(
  id: string
): Promise<{ editableNodes: EditableNode[]; url: string; filename: string; editable: boolean; next_document: number; }> {
  const resposnse = await fetchWithToken(`/documents/pdf_editor/${id}`);
  const data = await resposnse.json();
  return {
    editableNodes: data.layer,
    url: data.link,
    filename: data.filename,
    editable: data.editable,
    next_document: data.next_document,
  };
}

export async function savePdfDocument(id: string, editableNodes: EditableNode[], numberOfAddedSignatureOrInitialsFields: number): Promise<void> {
  const nodesWithoutNewAttribute = editableNodes.map(({ isNew: _isNew, ...item }) => item);
  await fetchWithToken('/documents/pdf_editor/save', {
    method: 'POST',
    body: urlEncodeBody({ document_id: id, layer: JSON.stringify(nodesWithoutNewAttribute), changes_made: numberOfAddedSignatureOrInitialsFields > 0 ? 1 : 0 })
  });
}

export async function savePdfDocumentAs(values: {
  docType: number;
  layer: EditableNode[];
  documentId: number;
  fileName: string;
}): Promise<{ url: string; newDocumentId: number }> {
  const nodesWithoutTempAttributes = values.layer.map(
    ({ isNew: _isNew, strikethrougWidth: _strikethrougWidth, ...item }) => item
  );
  const resposnse = await fetchWithToken('/documents/pdf_editor/save_as', {
    method: 'POST',
    body: urlEncodeBody({
      document_id: values.documentId,
      layer: JSON.stringify(nodesWithoutTempAttributes),
      file_name: values.fileName,
      doctype_id: values.docType
    })
  });
  const data = await resposnse.json();

  return { url: data.link, newDocumentId: data.new_document.id };
}

export async function shareDocumentForSignature(id: string, role: string): Promise<void> {
  await fetchWithToken(`documents/pdf_editor/${id}/share_for_sign`, {
    method: 'POST',
    body: urlEncodeBody({ 'roles[0]': role })
  });
}

export async function shareDocumentForSignatureToAll(id: string, note: string): Promise<void> {
  await fetchWithToken(`documents/pdf_editor/${id}/sign_to_all`, {
    method: 'POST',
    body: urlEncodeBody({ 'note': note })
  });
}

export async function getPeopleInTransaction(documentId): Promise<TransactionMember[]> {
  const resposnse = await fetchWithToken(`documents/pdf_editor/${documentId}/people`);
  const data = await resposnse.json();

  return data;
}

export async function getDocumentVariables(
  documentId
): Promise<{ key: string; text: string; value: any }[]> {
  const resposnse = await fetchWithToken(`documents/pdf_editor/${documentId}/variables`);
  const data = await resposnse.json();

  return data;
}

export async function getDocumentHistory(documentId): Promise<DocumentLayer[]> {
  const resposnse = await fetchWithToken(`documents/pdf_editor/${documentId}/history`);
  const data = await resposnse.json();

  return data.layers.map(item => ({
    id: item.id,
    name: item.filename,
    user: item.user_id,
    editableNodes: item.layer,
    createdOn: item.created_on * 1000
  }));
}
