import React, { useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dropdown, DropdownOption, Icon } from '@xchange/uikit';
import dayjs from 'dayjs';
import notify from 'notify';
import { navigate } from 'gatsby';
import PDFEditorContext from '../context';
import DocumentNavigation from './DocumentNavigation';

const Header = () => {
  const {
    editor,
    editorDispatch,
    historyQuery,
    setHistoryQuery,
    history,
    sendForSignatureToAll,
    saveDocument,
    documentIsEditable
  } = useContext(PDFEditorContext);
  const [loading, setLoading] = useState(false);
  const disableSendForSignatureToAllButton =
    loading ||
    !editor.changesSaved ||
    !documentIsEditable ||
    (editor.editableNodes.findIndex(item => item.type === 'signature') === -1 && editor.editableNodes.findIndex(item => item.type === 'initials') === -1);

  const documentHistoryOptions: DropdownOption[] = useMemo(
    () => [
      { value: 'base', text: 'Base PDF version' },
      ...history.map(item => ({
        value: String(item.id),
        text: dayjs(item.createdOn).format('ddd MMM D YYYY HH:MM A')
      }))
    ],
    [history]
  );
  
  

  const handleSendForSignatureToAllClick = async () => {
    setLoading(true);
    await sendForSignatureToAll();
    setLoading(false);
    if(editor.nextDocument > 0){
      editorDispatch({ type: 'SET_DOCUMENT_LOADED', value: false });
      console.log(`/pdf-editor?id=${editor.nextDocument}`);
      navigate(`/pdf-editor?id=${editor.nextDocument}`);
    }
  };
  return (
    <StyledHeader className="editor-header">
      <StyledDropdown
        buttonLike
        label={
          <>
            <Icon name="document-file" /> {editor.documentName}
          </>
        }
        options={documentHistoryOptions}
        value={historyQuery}
        onChange={setHistoryQuery}
      />
      <DocumentNavigation />
      <Button
        onClick={handleSendForSignatureToAllClick}
        disabled={disableSendForSignatureToAllButton}
        className="editor-header__signature-btn">
        {editor.buttonText}
      </Button>
      <Button
        onClick={saveDocument}
        disabled={!documentIsEditable}
        className="editor-header__save-btn">
        Save document
      </Button>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.div`
  margin: 0 16px 0 30px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  .editor-header {
    &__name {
      display: inline-flex !important;
      font-size: 12px;
      line-height: 16px;
      margin-right: auto;
    }
    &__signature-btn {
      margin-left: auto;
    }
    &__save-btn {
      margin-left: 12px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-button {
    border: none;
    padding: 0;
    height: auto;
    label {
      font-weight: 600;
      color: #000000;
      cursor: pointer;
    }
  }
  &.open {
    .dropdown-button {
      label {
        color: ${props => props.theme.colors.red};
      }
    }
  }
  &.button-like {
    display: block;
  }
  .dropdown-options {
    width: auto;
    z-index: 1002;

    .option {
      padding: 8px 12px 8px 36px;
    }
  }

  .dropdown-text {
    display: none;
  }

  label {
    white-space: nowrap;
  }
`;
